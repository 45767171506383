<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.app.adInfo.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      @gird-handle-select-click="selectionChange"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
    </grid-layout>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  remove,
  getList
} from '@/api/system/adInfo'

export default {
  name: "adInfo",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = this.selectionList.map(item=> item.id);
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          prop: "adName",
          span: 4,
          type:"input",
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.app.adInfo.field.adName"),
        },
        {
          prop: "adType",
          type: 'select',
          dataType: 'string',
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=AD_INFO_TYPE",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.app.adInfo.field.adType`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.app.adInfo.field.adName'),
            prop: 'adName',
            width: 340,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.adInfo.field.adType'),
            prop: 'adType',
            type: 'select',
            align: "center",
            width: 150,
            dataType: "string",
            dicUrl: `${baseUrl}AD_INFO_TYPE`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.adInfo.field.sort'),
            prop: 'sort',
            width: 80,
            align:'right',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.adInfo.field.remark'),
            prop: 'remark',
            align: "left",
            overHidden: true
          },
        ],
      }
    },
    gridRowBtn() {

        let result = [];
      //新增按钮
      if (this.permission.adInfo_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.adInfo_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.adInfo_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.adInfo_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
    },
    headRemove(){
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(this.ids).then((data) => {
            this.onLoad(this.page);
            this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'));
          });
        });
    },
    headAddTabs() {
      this.$router.push({
        path: `/ad/adInfoEdit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/ad/adInfoEdit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/ad/adInfoEdit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
